<template>
  <c-button
    variant="primary"
    @click="onAdd"
    :disabled="disabledWhenUnauthenticated"
    :ref="refButton"
    :block="buttonBlock"
    v-b-tooltip.hover.bottom="title"
  >
    <add-icon />
    <span>&nbsp;</span>
    {{ caption }}
  </c-button>
</template>

<script>
import { mapState } from 'vuex'
import { AddIcon } from '../icons'
import CButton from './Button'

export default {
  name: 'AddButton',
  components: {
    CButton,
    AddIcon
  },
  props: {
    refButton: {
      type: String,
      required: true
    },
    caption: {
      type: String,
      default: 'Tambah'
    },
    title: {
      type: String,
      required: true
    },
    buttonBlock: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('users', {
      isAuthenticated: 'authenticated'
    }),
    disabledWhenUnauthenticated () {
      if (this.disabled === false) {
        return this.isAuthenticated === false // enable if authenticated
      }

      return true
    }
  },
  methods: {
    onAdd () {
      this.$emit('add')
    }
  }
}
</script>
