<template>
  <b-button-group size="sm">
    <c-button
      :disabled="disabledWhenUnauthenticated"
      variant="primary"
      :tooltip-text="editBtnText"
      @click="onEdit"
    >
      <edit-icon />
    </c-button>
    <c-button
      :disabled="disabledWhenUnauthenticated"
      variant="outline-danger"
      :tooltip-text="removeBtnText"
      @click="onRemove"
    >
      <remove-icon />
    </c-button>
  </b-button-group>
</template>

<script>
import { mapState } from 'vuex'
import { join } from '@/helpers'
import { RemoveIcon, EditIcon } from '../icons'
import CButton from './Button'

export default {
  name: 'ActionButton',
  components: {
    CButton,
    RemoveIcon,
    EditIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('users', {
      isAuthenticated: 'authenticated'
    }),
    editBtnText () {
      return join(' ', ['Edit', this.title])
    },
    removeBtnText () {
      return join(' ', ['Hapus', this.title])
    },
    disabledWhenUnauthenticated () {
      if (this.disabled === false) {
        return this.isAuthenticated === false // enable if authenticated
      }

      return true
    }
  },
  methods: {
    onEdit () {
      this.$emit('edit')
    },
    onRemove () {
      this.$emit('remove')
    }
  }
}
</script>
