<template>
  <b-button
    variant="light"
    @click="goBack"
  >
    <back-icon />
  </b-button>
</template>

<script>
import { BackIcon } from '../icons'
import { propIs } from '@/helpers'

export default {
  name: 'BackButton',
  components: {
    BackIcon
  },
  data () {
    return {
      defaultRoute: '/'
    }
  },
  props: {
    redirectTo: {
      type: Object,
      required: true
    }
  },
  methods: {
    goBack () {
      const redirect = propIs(String, this.redirectTo, 'name')
        ? this.defaultRoute : this.redirectTo
      this.$router.push(redirect)
    }
  }
}
</script>
