export default {
  data () {
    return {
      tableData: [],
      loadingItems: false
    }
  },
  computed: {
    items () {
      return this.tableData
    },
    captions () {
      return this.fields
    }
  },
  methods: {
    async tableDataProvider () {
      this.loadingItems = true

      try {
        const { data } = await this.dataProvider()
        this.loadingItems = false
        this.setTableData(data)
      } catch (e) {
        this.loadingItems = false
        this.setTableData([])
      }
    },
    setTableData (data) {
      this.tableData = data
    }
  }
}
