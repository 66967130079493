import { mapMutations } from 'vuex'
import { delay, lectureNameConcat, isEmpty, join, Sheet } from '@/helpers'

export default {
  methods: {
    ...mapMutations('assessment', ['setTemplateType']),
    downloadTemplate (type, attrs = {}) {
      this.renderTemplate(type, attrs)
    },
    importUrlByTemplate (templateType) {
      this.setTemplateType(templateType)

      delay(120).then(() => {
        this.$router.push({
          name: 'dosen.import-mahasiswa-nilai',
          params: {
            kelasId: this.$route.params.kelasId
          }
        })
      })
    },
    renderTemplate (type, attrs) {
      this.dataProvider('all')
        .then(({ data }) => {
          let header = this.assessComponentData

          if (attrs.komPenId) {
            header = this.assessComponentData
              .filter((item) => item.komPenId === attrs.komPenId)
          }

          return [
            this.mappingHeader(header),
            this.mappingData(data)
          ]
        }).then(([header, data]) => {
          return [
            this.parseHeader(header),
            this.parseData(data, type)
          ]
        }).then(([parsedHeader, parsedData]) => {
          const defaultHeader = ['Id', 'NIM', 'Nama']

          const fileName = join('', [
            join('_', [
              'Template', 'Nilai',
              this.semesterNama, type, this.matakuliah.mkkurKode
            ]),
            '.xlsx'
          ])

          const namaDosen = lectureNameConcat(
            this.userProfile.pegNama,
            this.userProfile.pegGelarBelakang,
            this.userProfile.pegGelarDepan
          )

          Sheet.createAssessmentTemplate(
            defaultHeader,
            parsedHeader,
            parsedData,
            namaDosen,
            this.matakuliah,
            fileName
          )
        })
    },
    mappingHeader (header) {
      return header.map((item) => ({
        komPenId: item.komPenId,
        komPenNama: item.komPenNama,
        komPenPersen: item.komPenPersen
      }))
    },
    mappingData (data) {
      return data.map((item) => ({
        krsdtId: item.krsdtId,
        mhsNama: item.mhsNama,
        mhsNiu: item.mhsNiu,
        nilai: item.nilai
      }))
    },
    parseHeader (header) {
      return header
    },
    parseData (data, componentType) {
      return data.map(student => {
        let assessments = []
        if (!isEmpty(data.nilai)) {
          assessments = student.nilai.map(item => {
            if (componentType !== 'all') {
              if (item.komPenNama === componentType) {
                return {
                  komPenNama: item.komPenNama,
                  nilai: parseFloat(item.nilai)
                }
              }

              return null
            }

            return {
              komPenNama: item.komPenNama,
              nilai: parseFloat(item.nilai)
            }
          }).filter(item => item !== null)
        }

        return {
          krsdtId: student.krsdtId,
          mhsNiu: student.mhsNiu,
          mhsNama: student.mhsNama,
          assessments
        }
      })
    }
  }
}
