<template>
  <c-button
    :variant="variant"
    :tooltip-text="tooltipText"
    @click="$emit('onClick')"
    :disabled="disabled"
  >
    <slot name="icon" />
    <span v-if="caption !== ''" class="ml-2">{{ caption }}</span>
  </c-button>
</template>

<script>
import CButton from './Button'

export default {
  name: 'TitleIconButton',
  components: {
    CButton
  },
  props: {
    variant: {
      type: String,
      default: 'outline-primary'
    },
    caption: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: 'Tooltip Button'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
