<template>
  <b-button
    :disabled="disabled"
    :variant="variant"
    :type="buttonType"
    :class="buttonClass"
    :block="block"
    :size="size"
    v-b-tooltip.hover.bottom="tooltipText"
    @click="onClickHandler"
  >
    <slot />
  </b-button>
</template>

<script>
export default {
  name: 'CButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary'
    },
    buttonClass: {
      type: String,
      default: () => ''
    },
    buttonType: {
      type: String,
      default: 'button'
    },
    block: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    tooltipText: {
      type: [String, Boolean],
      default: false
    }
  },
  methods: {
    onClickHandler () {
      if (this.disabled === false) {
        this.$emit('click')
      }
    }
  }
}
</script>
