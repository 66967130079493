<template>
  <BaseHeader fixed class="shadow-sm">
    <SidebarToggler
      class="d-lg-none"
      display="md"
      mobile
    />
    <AppLogo class="ml-2" :logo-size="30" />
    <SidebarToggler
      class="d-md-down-none"
      display="lg"
      :default-open="sidebarState"
    />
    <b-navbar-nav class="ml-auto" />
  </BaseHeader>
</template>

<script>
import { mapState } from 'vuex'
import { Header as BaseHeader } from '@coreui/vue'
import { SidebarToggler } from '@/components/sidebars'
import AppLogo from './AppLogo'

export default {
  name: 'Header',
  components: {
    BaseHeader,
    SidebarToggler,
    AppLogo
  },
  computed: {
    ...mapState('users', ['user']),
    sidebarState () {
      const allowedSidebarOpened = ['dosen', 'mahasiswa']

      return allowedSidebarOpened.includes(this.user.role)
    }
  }
}
</script>
