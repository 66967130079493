<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-card body-class="px-0">
        <b-skeleton width="50%" height="10%"></b-skeleton>

        <dl class="row mt-3">
          <dt class="col-3">
            <b-skeleton></b-skeleton>
          </dt>
          <dd class="col-8">
            <b-skeleton width="70%"></b-skeleton>
          </dd>
          <dt class="col-3">
            <b-skeleton></b-skeleton>
          </dt>
          <dd class="col-8">
            <b-skeleton width="70%"></b-skeleton>
          </dd>
          <dt class="col-3">
            <b-skeleton></b-skeleton>
          </dt>
          <dd class="col-8">
            <b-skeleton width="70%"></b-skeleton>
          </dd>
          <dt class="col-3">
            <b-skeleton></b-skeleton>
          </dt>
          <dd class="col-8">
            <b-skeleton width="70%"></b-skeleton>
          </dd>
        </dl>
      </b-card>
    </template>

    <b-card body-class="px-0" v-bind="$attrs" class="mb-0">
      <slot />
    </b-card>
  </b-skeleton-wrapper>
</template>

<script>
export default {
  name: 'LoadingCard',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 4
    }
  }
}
</script>
