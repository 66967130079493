export default {
  data () {
    return {
      defaultWarningOpts: {
        title: 'Peringatan',
        okVariant: 'warning',
        size: 'sm',
        centered: true
      },
      defaultInfoOpts: {
        title: 'Info',
        okVariant: 'primary',
        size: 'sm',
        centered: true,
        okTitle: 'Ya',
        cancelTitle: 'Batal',
        hideHeaderClose: false
      }
    }
  },
  methods: {
    resolvedModalBox (message, opts) {
      return new Promise((resolve) => {
        resolve(this.$bvModal.msgBoxOk(message, opts))
      })
    },
    resolvedModalConfirmBox (message, opts) {
      return new Promise((resolve) => {
        resolve(this.$bvModal.msgBoxConfirm(message, opts))
      })
    },
    async warningModalMessage (message, opts = {}) {
      const options = Object.assign(this.defaultWarningOpts, opts)
      await this.resolvedModalBox(message, options)
    },
    modalMessage (message, opts = {}) {
      const options = Object.assign(this.defaultWarningOpts, opts)
      const modalPromise = this.resolvedModalBox(message, options)

      modalPromise.then(() => {
        if (typeof options.noRedirect === 'undefined') {
          return true // assumess it is resolved
        } else if (typeof options.redirectTo === 'object') {
          this.$router.push(options.redirectTo)
        } else {
          this.$router.back()
        }
      })
    },
    modalConfirmMessage (message, opts = {}) {
      const options = Object.assign(this.defaultInfoOpts, opts)
      const modalPromise = this.resolvedModalConfirmBox(message, options)

      modalPromise.then((confirmed) => {
        if (confirmed === true) {
          if (typeof options.redirectTo === 'object') {
            this.$router.push(options.redirectTo)
          }
          if (typeof options.actionAfter === 'function') {
            return options.actionAfter()
          }
        }

        return true // assumess it is resolved
      })
    }
  }
}
