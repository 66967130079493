<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-skeleton-table
        :rows="4"
        :columns="5"
        :table-props="tableSkeletonProps"
      >
      </b-skeleton-table>
    </template>

    <b-table
      hover
      fixed
      show-empty
      outlined
      borderless
      striped
      selected-variant="primary"
      stacked="sm"
      ref="table"
      empty-text="Tidak ada item untuk ditampilkan."
      :responsive="true"
      :items="items"
      :fields="captions"
      :primary-key="primaryKey"
      :foot-clone="footClone"
      :no-footer-sorting="noFooterSorting"
      :select-mode="selectMode"
      :selectable="selectable"
      @row-selected="onRowSelected"
    >
      <template
        v-for="(_, slot) of $scopedSlots"
        v-slot:[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </b-table>
  </b-skeleton-wrapper>
</template>

<script>
export default {
  name: 'CTable',
  props: {
    tableData: {
      type: [Array, Function],
      default: () => []
    },
    fields: {
      type: [Array, Object],
      default: () => []
    },
    primaryKey: {
      type: String,
      default: 'id'
    },
    loading: {
      type: Boolean,
      default: false
    },
    footClone: {
      type: Boolean,
      default: false
    },
    noFooterSorting: {
      type: Boolean,
      default: false
    },
    selectMode: {
      type: String,
      default: 'single'
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items () {
      const items = this.tableData
      return Array.isArray(items) ? items : items()
    },
    captions () {
      return this.fields
    },
    tableSkeletonProps () {
      return {
        outlined: true,
        striped: true,
        borderless: true,
        fixed: true
      }
    }
  },
  methods: {
    onRowSelected (items) {
      this.$emit('row-selected', items)
    }
  }
}
</script>
