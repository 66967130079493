<template>
  <BaseSidebar fixed class="shadow-sm">
    <SidebarNav />
  </BaseSidebar>
</template>

<script>
import {
  SidebarNav,
  Sidebar as BaseSidebar
} from '@/components/sidebars'

export default {
  name: 'Sidebar',
  components: {
    SidebarNav,
    BaseSidebar
  }
}
</script>
