<template>
  <c-button
    :disabled="disabled"
    :variant="variant"
    :type="buttonType"
    :class="buttonClass"
    :block="block"
    :size="size"
    @click="onClickHandler"
  >
    <b-spinner
      :small="spinnerSize"
      v-if="loading"
    />
    <slot v-else />
  </c-button>
</template>

<script>
import CButton from './Button'

export default {
  name: 'LoadingButton',
  components: {
    CButton
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary'
    },
    buttonClass: {
      type: String,
      default () {
        return ''
      }
    },
    buttonType: {
      type: String,
      default: 'submit'
    },
    loading: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'lg'
    }
  },
  computed: {
    spinnerSize () {
      return ['sm', 'md'].includes(this.size)
    }
  },
  methods: {
    onClickHandler () {
      if (this.disabled === false) {
        this.$emit('onClick')
      }
    }
  }
}
</script>
