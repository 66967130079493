<template>
  <b-alert
    show
    :variant="variant"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <b-icon :icon="icon" />
        <span class="ml-2">{{ message }}</span>
      </div>
      <slot name="alert-addon" />
    </div>
  </b-alert>
</template>

<script>
import { BIcon } from 'bootstrap-vue'

export default {
  name: 'Alert',
  components: {
    BIcon
  },
  props: {
    message: {
      type: String,
      default () {
        return 'Alert Message.'
      }
    },
    alertType: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    variant () {
      return this.typeHelper[this.alertType]
    }
  },
  data () {
    return {
      typeHelper: {
        warn: 'warning',
        error: 'danger',
        success: 'success'
      }
    }
  }
}
</script>
