<template>
  <div class="d-flex">
    <div class="d-inline-flex">
      <img
        class="mr-2"
        :src="logo"
        :width="logoSize"
        height="100%"
        alt="Portal Akademik UA"
      >
    </div>
    <div class="d-flex flex-column align-self-center">
      <span class="text-dark font-sm font-weight-bold text-left" style="line-height: 1">
        Portal Akademik
      </span>
      <span class="text-dark font-sm font-weight-bold text-left" style="line-height: 1">
        Universitas Andalas
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLogo',
  props: {
    logoSize: {
      type: Number,
      default: 30
    }
  },
  data () {
    return {
      logo: require('@/assets/img/original.png')
    }
  }
}
</script>
