import {
  BIconInfoCircle as InfoIcon,
  BIconPlusSquare as AddIcon,
  BIconTrash as RemoveIcon,
  BIconCursor as EditIcon,
  BIconFileArrowDown as DownloadIcon,
  BIconShieldExclamation as WarnIcon,
  BIconArrowUpLeftSquare as BackIcon,
  BIconCheckCircle as CheckedIcon,
  BIconCircle as UncheckedIcon,
  BIconDashCircle as IndeterminateIcon,
  BIconJournalAlbum as ComponentIcon,
  BIconFileArrowUp as UploadIcon,
  BIconXCircle as FailedIcon,
  BIconCheck2Circle as SuccessIcon,
  BIconFileEarmarkRuled as AssessmentIcon,
  BIconArrowRepeat as RefreshIcon,
  BIconLock as PasswordIcon,
  BIconPerson as UsernameIcon,
  BIconXOctagon as DangerIcon
} from 'bootstrap-vue'

export {
  InfoIcon,
  AddIcon,
  RemoveIcon,
  EditIcon,
  DownloadIcon,
  WarnIcon,
  BackIcon,
  CheckedIcon,
  UncheckedIcon,
  IndeterminateIcon,
  ComponentIcon,
  UploadIcon,
  FailedIcon,
  SuccessIcon,
  AssessmentIcon,
  RefreshIcon,
  PasswordIcon,
  UsernameIcon,
  DangerIcon
}
